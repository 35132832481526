<template>
  <div>
    <article>
      <h1 class="mb-4">{{ $t("pages.dashboard.title") }}</h1>
      <div class="d-flex p-2 table-responsive">
        <employee-pay-roll-for-sign-widget v-if="activeEmployeePayrolls" />
        <employee-vacation-day-widget class="ml-4" v-if="activeEmployeePermissions" />
        <hour-balance-widget class="ml-4" v-if="activeHoursPack" />
      </div>
    </article>

    <article class="mt-5 plan" v-if="activeEmployeePlanning">
      <h1 class="mb-4">{{ $t("pages.dashboard.my_plan") }}</h1>

      <planning-calendar-component :type="typeWeekCalendar">
        <template #calendar-right>
          <router-link :to="{ name: 'planning' }" class="nav-link text-right m-2 p-0">
            {{ $t("pages.dashboard.month_plan") }}
            <img src="@/assets/IC_Arrow_Right_pink_secondary.svg" alt="next" class="ml-1" />
          </router-link>
        </template>
      </planning-calendar-component>
    </article>
  </div>
</template>

<script>
import EmployeePayRollForSignWidget from "@/pages/dashboard/widgets/EmployeePayRollForSignWidget.vue";
import EmployeeVacationDayWidget from "@/pages/dashboard/widgets/EmployeeVacationDayWidget.vue";
import { HourBalanceWidget } from "@/pages/employee/planning/widgets";
import { PlanningCalendarComponent } from "@/pages/employee/planning/components";
import { DATE_PERIOD_WEEK } from "@/components/form";

import { mapGetters } from "vuex";

export default {
  components: {
    HourBalanceWidget,
    PlanningCalendarComponent,
    EmployeePayRollForSignWidget,
    EmployeeVacationDayWidget,
  },
  computed: {
    ...mapGetters("auth", [
      "activeHoursPack",
      "activeEmployeePayrolls",
      "activeEmployeePermissions",
      "activeEmployeePlanning",
    ]),
    typeWeekCalendar() {
      return DATE_PERIOD_WEEK;
    },
  },
};
</script>
