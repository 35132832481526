<template>
  <div class="card plan-card m-1">
    <div class="card-body">
      <div class="d-flex justify-content-between plan-day-header">
        <div
          class="day d-flex"
          :class="{ 'day-other-month': isCurrentMonth, 'day-current': isCurrentDay }"
        >
          <span class="number">{{ dayDate }}</span>
        </div>
        <!-- <img src="@/assets/IC_Ausencia_Pendiente.png" width="24" height="24" alt="more" /> -->
      </div>
      <!-- <div class="d-flex flex-column">
        <article class="plan-day-body">
          <h6 class="plan-text-header">{{ $t("pages.planning.alert") }}</h6>
          <p class="plan-text-body">-</p>
        </article>
      </div> -->
      <div class="d-flex flex-column">
        <article class="plan-day-body">
          <h6 class="plan-text-header">{{ $t("pages.planning.planning_hours") }}</h6>
          <div class="plan-text-body">
            <div v-if="issetFirstTime">{{ firstTime }}</div>
            <div v-if="issetSecondTime">{{ secondTime }}</div>
            <div v-if="!issetFirstTime && !issetSecondTime">-</div>
          </div>
        </article>
      </div>
      <!-- <div class="d-flex flex-column">
        <article class="plan-day-body">
          <h6 class="plan-text-header">Lugar de trabajo</h6>
          <p class="plan-text-body">Tienda central</p>
        </article>
      </div> -->
    </div>
  </div>
</template>

<script>
import { CalendarCell } from "@/components/calendar";
import { formatTime } from "@/utils/functions";

export default {
  props: {
    cell: {
      type: CalendarCell,
      required: true,
    },
  },
  computed: {
    dayDate() {
      return this.cell.day.getDate();
    },
    isCurrentMonth() {
      return this.cell.day.getMonth() != this.cell.month;
    },
    isCurrentDay() {
      let current = new Date();
      return (
        this.cell.day.getDate() === current.getDate() &&
        this.cell.day.getMonth() == current.getMonth()
      );
    },
    issetFirstTime() {
      return (
        this.$isset(this.cell.data.firstPartStartingTime) &&
        this.$isset(this.cell.data.firstPartEndingTime)
      );
    },
    issetSecondTime() {
      return (
        this.$isset(this.cell.data.secondPartStartingTime) &&
        this.$isset(this.cell.data.secondPartEndingTime)
      );
    },
    firstTime() {
      return this.issetFirstTime
        ? `${formatTime(this.$parseTime(this.cell.data.firstPartStartingTime))} - ${formatTime(
            this.$parseTime(this.cell.data.firstPartEndingTime)
          )}`
        : null;
    },
    secondTime() {
      return this.issetSecondTime
        ? `${formatTime(this.$parseTime(this.cell.data.secondPartStartingTime))} - ${formatTime(
            this.$parseTime(this.cell.data.secondPartEndingTime)
          )}`
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-bar {
  border-radius: $border-radius;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(58, 0, 159, 0.1);
}

.plan-card {
  height: 200px;
  width: 150px;
  border-radius: $border-radius;

  & > .card-body {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.day {
  color: $color-white;
  background-color: $color-primary;
  border-radius: $border-radius 0;
}

.day-other-month {
  color: $color-white;
  background-color: $color-gray;
}

.day-current {
  color: $color-white;
  background-color: $color-secondary;
}

.number {
  font-size: 24px;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
  padding: 10px;
}

.plan-text-header,
.plan-text-body {
  color: $color-gray;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
  margin: 0;
}

.plan-text-body {
  color: $color-black;
  font-size: 16px;
}

.plan-day-body {
  margin: 0.5rem !important;
}

.plan-day-header {
  & > img {
    margin-top: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
</style>
